* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  color: rgb(1, 9, 12);
}
p {
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 300;
}

h1 {
  font-size: 64px;
  color: #fff;
}

.footerP {
  color: #fff;
  font-size: 16px;
  margin-left: 8px;
}

.reviewP {
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
}
.nav-btns {
  color: rgba(161, 8, 59, 1);
  font-weight: 600;
  font-size: 16px;
}
.social-icons {
  cursor: pointer;
  color: #fff;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
}

.review-enter {
  transform: translateY(-80%);
}
.review-enter-active {
  transform: translateY(0%);
  transition: transform 300ms;
}
.review-exit {
  transform: translateY(0%);
}
.review-exit-active {
  transform: translateY(60%);
  transition: transform 200ms;
}
